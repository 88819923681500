<template>
  <div class="md:px-2">
    <table
      class="schedules-table mt-8 w-full border-b border-gray-300 shadow-sm"
    >
      <thead class="darkgray-bg-color h-16">
        <tr class="border">
          <th class="w-20"></th>
          <th class="w-20"></th>
          <th
            class="text-xs font-semibold text-black"
            v-for="(date, key) in dates"
            :key="key"
          >
            {{ date }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isAdminOrSuperUser">
          <tr class="border p-2">
            <td colspan="2">
              <strong>{{ $t("day_template") }}</strong>
            </td>
            <td class="text-xs" v-for="(date, key, index) in dates" :key="key">
              <t-select
                class="w-44"
                :value="templateFilter[date]"
                :options="templates"
                value-attribute="id"
                text-attribute="name"
                v-bind:disabled="!isUnpublished"
                placeholder="Select Template"
                @click="allowChange = true"
                @change="templateChange($event, date, index + 1)"
              ></t-select>
            </td>
          </tr>
        </template>
        <template v-for="(timeSlots, jobName) in groupedSchedules">
          <tr
            v-for="(scheduleDates, timeSlotKey) in timeSlots"
            :key="`${jobName}-${timeSlotKey}`"
            class="border border-gray-200"
          >
            <td class="text-sm align-baseline border-r border-gray-200 p-2">
              {{ isJobFirstTimeSlot(jobName, timeSlotKey) ? jobName : "" }}
            </td>
            <td class="text-sm align-baseline border-r border-gray-200 p-2">
              {{ timeSlotKey }}
            </td>
            <td
              class="align-baseline border-r border-gray-200 p-2"
              v-for="(date, key) in dates"
              :key="key"
            >
              <template v-for="schedule in scheduleDates[date]">
                <div
                  class="mt-2 flex"
                  :key="`user-${schedule.id}`"
                  v-if="schedule"
                >
                  <hover-popover
                    v-if="!schedule.available && schedule.user"
                    :loading="availableScheduleLoading"
                    :title="`${$t('user_available_schedule')}:`"
                    @hover="showUserAvailables(schedule.user.id)"
                  >
                    <template v-slot:hoverable>
                      <button
                        class="text-xs mr-4 hover:underline"
                        :class="schedule | scheduleState"
                        @click="showAssignEmployeeModal(schedule)"
                      >
                        {{ schedule | scheduleUserName }}

                        <template v-if="(isLocationBar5 || isLocationBar10) && schedule.user">
                          <span
                            v-for="(tag, key) in schedule.user.tags"
                            :key="key"
                          >
                            <span v-for="index in tag.qty" :key="index">
                              {{ getTagEmoji(tag) }}
                            </span>
                          </span>
                        </template>

                        <i
                          v-if="schedule.holiday"
                          class="fas fa-exclamation-circle"
                        ></i>
                      </button>
                    </template>
                    <template v-slot:content>
                      <p
                        v-for="(available, date) in hoveredUserAvailables"
                        :key="date"
                        :class="{ 'text-gray-300': available.is_scheduled }"
                        class="text-sm"
                      >
                        {{ available.date }}
                      </p>
                    </template>
                  </hover-popover>

                  <button
                    v-else
                    class="text-xs mr-4 hover:underline"
                    :class="schedule | scheduleState"
                    @click="showAssignEmployeeModal(schedule)"
                  >
                    {{ schedule | scheduleUserName }}

                    <template v-if="(isLocationBar5 || isLocationBar10) && schedule.user">
                      <span v-for="(tag, key) in schedule.user.tags" :key="key">
                        <span v-for="index in tag.qty" :key="index">
                          {{ getTagEmoji(tag) }}
                        </span>
                      </span>
                    </template>

                    <i
                      v-if="schedule.holiday"
                      class="fas fa-exclamation-circle"
                    ></i>
                  </button>

                  <i
                    @click="removeTimeslot(schedule.id)"
                    class="fas fa-times-circle text-red-700 cursor-pointer"
                  ></i>
                </div>
                <span class="text-xs mb-2" :key="`timeslot-${schedule.id}`">
                  ({{ schedule.start | scheduleDuration(schedule.ending) }})
                </span>
              </template>
            </td>
          </tr>
          <tr class="breakline" :key="`${jobName}-breakline`">
            <td colspan="9"></td>
          </tr>
        </template>
      </tbody>
    </table>
    <!-- Edit Timeslot Modal -->
    <t-modal v-model="toggleEditTimeslotModal" :header="editTimeSlotHeader">
      <div class="flex items-center justify-between">
        <search-bar
          :users="employees"
          @clicked="assignUserToTimeSlot"
        ></search-bar>
        <div class="flex items-center">
          <div class="ml-1">
            <div>Planned</div>
            <div class="bg-green-300 w-24 h-5"></div>
          </div>
          <div class="ml-1">
            <div>Available</div>
            <div class="bg-orange-300 w-24 h-5"></div>
          </div>
          <div class="ml-1">
            <div>Unavailable</div>
            <div class="bg-gray-300 w-24 h-5"></div>
          </div>
        </div>
      </div>
      <table class="w-full table-auto mt-5" v-if="selectedSchedule">
        <colgroup>
          <col span="1" style="width: 30%" />
          <col span="1" style="width: 30%" />
          <col span="1" style="width: 20%; min-width: 190px" />
          <col span="1" style="max-width: 10px" />
          <col span="1" style="max-width: 10px" />
        </colgroup>
        <tr
          class="
            w-1/3
            cursor-pointer
            text-sm text-left
            p-2
            hover:underline
            text-blue-500
          "
        >
          <th
            class="
              w-1/3
              cursor-pointer
              text-sm text-left
              p-2
              hover:underline
              text-blue-500
            "
            @click="updateSchedule(selectedSchedule)"
          >
            {{ $t("edit_timeslot_to_empty") }}
          </th>
          <th
            class="
              w-1/3
              cursor-pointer
              text-sm text-left
              p-2
              hover:underline
              text-blue-500
            "
            @click="updateSchedule(selectedSchedule)"
          >
            {{
              selectedSchedule.start | scheduleDuration(selectedSchedule.ending)
            }}
          </th>
          <th></th>
          <th class="text-center">
            <i class="far fa-calendar-check" title="Planned total"></i>
          </th>
          <th class="text-center">
            <i class="fas fa-minus-circle" title="Max days"></i>
          </th>
        </tr>
        <tr
          class="border-b border-gray-200"
          v-for="personnel in personnels"
          :key="personnel.user_id"
        >
          <td
            class="text-sm p-2 flex"
            @click="updateSchedule(selectedSchedule, personnel)"
          >
            <p :class="editTimeslotEmployeeClass(personnel)">
              {{ personnel.user_name }}
            </p>

            <template v-if="(isLocationBar5 || isLocationBar10) && personnel.tags">
              <p class="pl-2">
                <span v-for="(tag, key) in personnel.tags" :key="key">
                  <span v-for="index in tag.qty" :key="index">
                    {{ getTagEmoji(tag) }}
                  </span>
                </span>
              </p>
            </template>

            <p
              v-if="getLastBusTime(personnel.user_id)"
              class="pl-2 text-gray-500"
            >
              <i class="fas fa-bus"></i>
              ({{ getLastBusTime(personnel.user_id) }})
            </p>
            <i
              v-if="personnel.holiday || personnel.free_day"
              class="fas fa-exclamation-circle text-gray-500"
            ></i>
          </td>
          <td
            class="cursor-pointer text-sm p-2 hover:underline"
            :class="editTimeslotEmployeeClass(personnel)"
            @click="updateSchedule(selectedSchedule, personnel)"
          >
            {{
              personnel.available_start_datetime
                | scheduleDuration(personnel.available_end_datetime)
            }}
          </td>
          <td class="cursor-pointer text-sm p-2">
            <tool-tip :title="`${personnel.user_name}`">
              <template v-slot:hoverable>
                <t-tag
                  v-for="value in sortedWeekdays"
                  :variant="getBadgeColor(value, personnel)"
                  :key="value.day"
                >
                  {{ getTranslation(value.day, true) }}
                </t-tag>
              </template>
              <template v-slot:content>
                <table>
                  <tr
                    v-for="value in getWeeklySchedule(personnel.user_id)"
                    :key="value.day"
                  >
                    <td class="px-3">
                      <b> {{ getTranslation(value.day) }} </b>
                    </td>
                    <td>
                      {{ value.start | scheduleDuration(value.end) }}
                    </td>
                  </tr>
                </table>
              </template>
            </tool-tip>
          </td>
          <td class="text-center">
            <t-tag variant="defaultBadge">{{ personnel.total_planned }}</t-tag>
          </td>
          <td class="text-center">
            <t-tag v-if="personnel.max_days !== null" variant="defaultBadge">
              <span
                :class="{
                  'text-red-500': personnel.total_planned > personnel.max_days,
                }"
              >
                {{ personnel.max_days }}
              </span>
            </t-tag>
          </td>
        </tr>
      </table>
      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button class="text-sm" @click="toggleEditTimeslotModal = false">
            {{ $t("close") }}
          </t-button>
        </div>
      </template>
    </t-modal>
    <!-- ./Edit Timeslot Modal -->
  </div>
</template>

<script>
import HoverPopover from "@/components/HoverPopover.vue";
import SearchBar from "@/components/SearchBar.vue";
import ToolTip from "@/components/Tooltip.vue";
import { horecaLocations, personnelStatus, tagEmojis } from "@/constants/misc";
import { actions, getters } from "@/constants/state";
import i18n from "@/i18n";
import { checkIfPublished } from "@/services/published-schedule-service";
import {
  getAllLastBusByIds,
  getPersonnel,
  getUsersWeekSchedule,
  setNewRoosterDayTemplate,
  update,
} from "@/services/scheduledt-service";
import { getByDateRange } from "@/services/template-schedule-service";
import {
  getAvailableSchedules,
  getScheduledUsers,
} from "@/services/user-service";
import {
  getEndWeekDuration,
  getWeekDatesFromDate,
  getWeekDayTranslationShort,
  getWeekOptions,
  weekdaySorter,
} from "@/utils/misc";
import moment from "@/utils/moment-utc";
import {
  filter,
  find,
  first,
  get,
  isEmpty,
  map,
  orderBy,
  reduce,
  set,
  size,
  sortBy,
  upperFirst,
} from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { HoverPopover, SearchBar, ToolTip },
  data: () => ({
    initialLoad: true,
    toggleEditTimeslotModal: false,
    editTimeSlotHeader: "",
    selectedSchedule: null,
    personnels: [],
    employees: [],
    hoveredUserAvailables: null,
    availableScheduleLoading: false,
    usersSchedules: {},
    templateFilter: {},
    lastBusTimes: {},
    isUnpublished: false,
    allowChange: false,
  }),
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      getSchedulesDt: actions.SCHEDULEDT_FETCH_ACTION,
      getTemplates: actions.TEMPLATES_FETCH_ACTION,
      removeScheduleDt: actions.SCHEDULEDT_REMOVE_ACTION,
    }),
    async loadData() {
      this.$emit("setLoading", true);
      this.initialLoad = true;
      this.allowChange = false;

      await Promise.all([
        this.checkPublishedSchedule(),
        this.getTemplates(),
        this.getTemplateSchedule(),
      ]);

      this.initialLoad = false;
      this.$emit("setLoading", false);
    },
    isJobFirstTimeSlot(jobName, timeSlotKey) {
      return first(Object.keys(this.groupedSchedules[jobName])) === timeSlotKey;
    },
    async showUserAvailables(userId) {
      try {
        this.availableScheduleLoading = true;
        const { data } = await getAvailableSchedules(
          this.mainFilter.week,
          getEndWeekDuration(this.mainFilter),
          userId
        );
        this.hoveredUserAvailables = data;
      } catch (error) {
        this.hoveredUserAvailables = null;
        console.error("DEBUG: showUserAvailables", error);
      }

      this.availableScheduleLoading = false;
    },
    async removeTimeslot(id) {
      const { isCancel } = await this.$dialog.confirm(
        this.$i18n.t("new_rooster_confirm_remove")
      );

      if (isCancel) {
        return;
      }

      this.$emit("setLoading", true);

      try {
        await this.removeScheduleDt(id);
      } catch (error) {
        console.error("DEBUG: removeTimeslot", error);
      }

      this.$emit("setLoading", false);
    },
    async updateSchedule(schedule, employee = null) {
      if (get(employee, "already_planned")) {
        return;
      }

      if (get(employee, "free_day")) {
        const { isCancel } = await this.$dialog.confirm(
          this.$i18n.t("new_rooster_employee_is_free_confirmation")
        );

        if (isCancel) {
          return;
        }
      }

      const scheduleEndTime = moment(schedule.ending).format("HH:mm");

      let scheduleEnding = get(
        employee,
        "available_end_datetime",
        schedule.ending
      );

      if (scheduleEndTime == "07:00") {
        scheduleEnding = schedule.ending;
      }

      this.$emit("setLoading", true);

      try {
        await update(
          schedule.id,
          get(employee, "available_start_datetime", schedule.start),
          scheduleEnding,
          get(employee, "user_id")
        );
        await this.getSchedulesDt({
          from: this.mainFilter.week,
          until: getEndWeekDuration(this.mainFilter.week),
          jobFilterId: this.mainFilter.jobFilterId,
        });
        this.toggleEditTimeslotModal = false;
      } catch (error) {
        console.error("DEBUG: updateSchedule", error);
      }

      this.$emit("setLoading", false);
    },
    async showAssignEmployeeModal(schedule) {
      this.$emit("setLoading", true);

      try {
        this.selectedSchedule = schedule;
        const { data } = await getPersonnel(
          this.selectedSchedule.id,
          this.mainFilter.jobFilterId,
          this.selectedSchedule.job.id,
          get(this.dates, this.$t("monday")),
          get(this.dates, this.$t("sunday"))
        );

        const date = moment(this.selectedSchedule.start).format("YYYY-MM-DD");
        const start = moment(this.selectedSchedule.start).format("HH:mm");
        const ending = moment(this.selectedSchedule.ending).format("HH:mm");
        this.editTimeSlotHeader = `${date} | ${start} - ${ending} | ${this.selectedSchedule.job.name}`;

        this.personnels = orderBy(data, "user_name", "asc");

        const users = !isEmpty(data) ? Object.keys(data) : [0];

        const result = await getUsersWeekSchedule(
          Object.values(this.dates),
          users
        );

        const horecaIds = {};
        map(
          this.personnels,
          (personnel) =>
            (horecaIds[personnel.user_id] = personnel.horeca_user_id)
        );
        if (!isEmpty(horecaIds)) {
          const busTimes = await getAllLastBusByIds(horecaIds);
          this.lastBusTimes = get(busTimes, "data");
        }

        this.usersSchedules = get(result, "data", {});
        this.getEmployeesList();

        this.toggleEditTimeslotModal = true;
      } catch (error) {
        this.editTimeSlotHeader = "";
        this.personnels = [];
        console.error("DEBUG: showAssignEmployeeModal", error);
      }

      this.toggleEditTimeslotModal = true;
      this.$emit("setLoading", false);
    },
    async getEmployeesList() {
      try {
        const { data } = await getScheduledUsers(this.selectedSchedule.start);
        this.employees = data;
      } catch (error) {
        this.employees = [];
        console.error("DEBUG: get unavailable users", error);
      }
    },
    async assignUserToTimeSlot(user) {
      this.$emit("setLoading", true);

      try {
        await update(
          get(this.selectedSchedule, "id"),
          get(this.selectedSchedule, "start"),
          get(this.selectedSchedule, "ending"),
          get(user, "user_id")
        );
        await this.getSchedulesDt({
          from: this.mainFilter.week,
          until: getEndWeekDuration(this.mainFilter.week),
          jobFilterId: this.mainFilter.jobFilterId,
        });
      } catch (error) {
        console.error("DEBUG: updateSchedule", error);
      }

      this.toggleEditTimeslotModal = false;
      this.$emit("setLoading", false);
    },
    editTimeslotEmployeeClass(employee) {
      if (employee.already_planned) {
        return "text-red-500";
      }

      if (
        employee.already_working_today ||
        (!isEmpty(employee.tags) &&
          !isEmpty(find(employee.tags, { tagid: tagEmojis.GOOD_AVAILABILITY })))
      ) {
        return "text-green-500 cursor-pointer hover:underline";
      }

      if (employee.holiday) {
        return "text-gray-500 cursor-pointer hover:underline";
      }

      if (employee.free_day) {
        return "text-amber-500 cursor-pointer hover:underline";
      }

      return "text-blue-500 cursor-pointer hover:underline";
    },
    async getTemplateSchedule() {
      this.setTemplateFilter();

      try {
        const { data } = await getByDateRange({
          from: get(this.dates, this.$t("monday")),
          until: get(this.dates, this.$t("sunday")),
          jobFilterId: this.mainFilter.jobFilterId,
        });

        const dates = {};
        map(this.templateFilter, (value, date) => {
          dates[date] = get(
            filter(
              data,
              (schedule) =>
                schedule.date === date &&
                schedule.job_filter_id === this.mainFilter.jobFilterId
            ),
            "0.template_id"
          );
        });
        this.templateFilter = dates;
        this.initialLoad = true;
      } catch (error) {
        console.error("DEBUG: getTemplateSchedule", error);
      }
    },
    async templateChange(newTemplateId, date, day) {
      if (!this.allowChange) {
        return;
      }

      if (["string", "number"].includes(typeof newTemplateId)) {
        this.templateFilter[date] = newTemplateId;

        try {
          await setNewRoosterDayTemplate({
            newTemplateId,
            jobFilterId: this.mainFilter.jobFilterId,
            from: date,
            until: date,
            day,
          });

          await this.getSchedulesDt({
            from: this.mainFilter.week,
            until: getEndWeekDuration(this.mainFilter.week),
            jobFilterId: this.mainFilter.jobFilterId,
          });
        } catch (error) {
          console.error("DEBUG: templateChange", error);
        }
      }
    },
    async checkPublishedSchedule() {
      this.$emit("setLoading", true);

      try {
        const { data } = await checkIfPublished({
          start: get(this.dates, this.$t("monday")),
          jobFilterId: this.mainFilter.jobFilterId,
        });

        this.isUnpublished = !data;
      } catch (error) {
        console.error("DEBUG: checkPublishedSchedule", error);
      }

      this.$emit("setLoading", false);
    },
    setTemplateFilter() {
      const dates = {};
      map(Object.values(this.dates), (date) => set(dates, date, null));

      this.templateFilter = dates;
    },

    getLastBusTime(userId) {
      const user = get(this.lastBusTimes, `${userId}`);
      let timeText = "-";

      if (!isEmpty(this.lastBusTimes)) {
        timeText = user ? get(user, "time") : null;
      }

      return timeText;
    },
    getTagEmoji(tag) {
      switch (tag.tagid) {
        case tagEmojis.SATURDAY_HERO:
          return "🕺";
        case tagEmojis.TOP_AVAILABILITY:
          return "💪";
      }
    },
  },
  computed: {
    ...mapGetters({
      horecaLocationId: getters.DOMAIN_LOCATION_ID_GETTER,
      isAdminOrSuperUser: getters.AUTH_IS_ADMIN_OR_SUPERUSER_GETTER,
      mainFilter: getters.SESSION_MAIN_FILTER_GETTER,
      schedulesDt: getters.SCHEDULEDT_GETTER,
      templates: getters.TEMPLATES_GETTER,
    }),
    groupedSchedules() {
      return reduce(
        this.schedulesDt,
        (result, schedule) => {
          const startDate = moment(schedule.start).format("YYYY-MM-DD");
          const startTime = moment(schedule.start).format("HH:mm");
          const timeSlots = {
            ...(result[schedule.job.name] || []),
            [startTime]: {
              ...(get(result, `${schedule.job.name}.${startTime}`) || []),
              [startDate]: [
                ...(get(
                  result,
                  `${schedule.job.name}.${startTime}.${startDate}`
                ) || []),
                schedule,
              ],
            },
          };
          const sortedTimeKeys = sortBy(Object.keys(timeSlots), (time) =>
            moment.duration(time).asMinutes()
          );
          let sorted = {};

          for (let time of sortedTimeKeys) {
            sorted[time] = timeSlots[time];
          }

          return {
            ...result,
            [schedule.job.name]: sorted,
          };
        },
        {}
      );
    },
    weeks() {
      return getWeekOptions(this.isAdminOrSuperUser);
    },
    dates() {
      return getWeekDatesFromDate(this.mainFilter.week);
    },
    sortedWeekdays() {
      return weekdaySorter(this.dates);
    },
    getTranslation() {
      return (day, initial) => getWeekDayTranslationShort(day, initial);
    },
    getBadgeColor() {
      return (today, schedule) => {
        const userPlannedDay = find(this.getWeeklySchedule(schedule.user_id), {
          day: upperFirst(today.day),
        });

        if (get(userPlannedDay, "start")) {
          return personnelStatus.PLANNED;
        }

        const dateToday = moment(today.date);
        const freeDayToday = first(
          filter(get(schedule, "free_days"), (freeDay) =>
            dateToday.isSame(freeDay.from, "day")
          )
        );

        if (schedule.is_full_timer) {
          if (!freeDayToday) {
            return personnelStatus.AVAILABLE;
          }

          const withinFreeDay = filter(this.schedulesDt, (schedule) => {
            const from = moment(schedule.start);
            const until = moment(schedule.ending);

            if (!from.isSame(dateToday, "day")) {
              return false;
            }

            return (
              from.isBetween(
                freeDayToday.from,
                freeDayToday.until,
                undefined,
                "[]"
              ) ||
              until.isBetween(
                freeDayToday.from,
                freeDayToday.until,
                undefined,
                "[]"
              )
            );
          });

          return size(withinFreeDay)
            ? personnelStatus.UNAVAILABLE
            : personnelStatus.AVAILABLE;
        }

        const available = get(schedule, `availables.${today.date}`);

        return !available || (available && available.has_holiday)
          ? personnelStatus.UNAVAILABLE
          : personnelStatus.AVAILABLE;
      };
    },
    getWeeklySchedule() {
      return (userId) => {
        const userSchedules = get(this.usersSchedules, `${userId}`);
        if (userSchedules) {
          return reduce(
            this.sortedWeekdays,
            (result, value) => {
              let object = {
                day: value.day,
                startDate: null,
                endDate: null,
              };
              const schedule = find(userSchedules, (userSchedule) => {
                return (
                  moment(userSchedule.start).format("YYYY-MM-DD") ===
                  moment(value.date).format("YYYY-MM-DD")
                );
              });

              if (schedule) {
                set(object, "start", moment(schedule.start));
                set(object, "end", moment(schedule.ending));
              }

              result.push(object);
              return result;
            },
            []
          );
        }
      };
    },
    isLocationBar5() {
      return this.horecaLocationId == horecaLocations.BAR_5;
    },
    isLocationBar10() {
      return this.horecaLocationId == horecaLocations.BAR_10;
    },
  },
  filters: {
    scheduleUserName(schedule) {
      return get(schedule, "user.name") || `[${i18n.t("empty")}]`;
    },
    scheduleState(schedule) {
      if (!schedule.user) {
        return "text-red-700";
      }

      if (schedule.holiday) {
        return "text-gray-500";
      }

      if (schedule.user_id && schedule.always && !schedule.available) {
        return "text-red-500";
      }

      return "text-blue-500";
    },
    scheduleDuration(start, end) {
      if (!start || !end) {
        return "-";
      }
      let endTime = moment(end).format("HH:mm");

      switch (endTime) {
        case "07:00":
          endTime = i18n.t("call");
          break;
        case "02:00":
          endTime = i18n.t("close");
          break;
        case "02:30":
          endTime = i18n.t("needed");
          break;
      }

      return `${moment(start).format("HH:mm")} - ${endTime}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.breakline {
  td {
    height: 1px;
    padding: 1px !important;
    margin: 0;
    background-color: #000;
  }
}

.schedules-table {
  td,
  th {
    padding: 8px;
    line-height: 1.42857143;
    border: 1px solid #ddd;

    &:nth-child(n + 3) {
      min-width: 150px;
    }
  }
}
</style>
